<template>
  <v-container fluid>
    <v-row>
      <!-- Main Card -->

      <v-col cols="12" sm="4">
        <v-card class="nftw-box pa-1" aspect-ratio="1" rounded="0">
          <v-row>
            <v-col cols="12">
              <v-card class="pa-lg-4 pa-sm-4" flat>
                <h1>TokenPenguin.World</h1>
                <div
                  style="position: relative; padding-top: 157.63546798029557%"
                >
                  <iframe
                    src="https://customer-du4m7zrponajpk1w.cloudflarestream.com/e82d5d9ec2053f27bd7b907ba9bcf6b9/iframe?preload=true&poster=https%3A%2F%2Fcustomer-du4m7zrponajpk1w.cloudflarestream.com%2Fe82d5d9ec2053f27bd7b907ba9bcf6b9%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
                    style="
                      border: none;
                      position: absolute;
                      top: 0;
                      left: 0;
                      height: 100%;
                      width: 100%;
                    "
                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                    allowfullscreen="true"
                  ></iframe>
                </div>

                <v-card-actions class="my-3">
                  <v-btn color="pink" to="/pcp-checkout" block
                    ><v-icon class="my-2 mr-2">mdi-penguin</v-icon>Hatch a
                    Penguin Chick<v-icon class="my-2 ml-2"
                      >mdi-penguin</v-icon
                    ></v-btn
                  >
                  <br />
                </v-card-actions>

                <v-card-actions>
                  <v-btn color="yellow darken-2" to="pip-checkout" block
                    ><v-icon class="my-2 mr-2">mdi-penguin</v-icon>Sponsor an
                    Incubator<v-icon class="my-2 ml-2"
                      >mdi-penguin</v-icon
                    ></v-btn
                  >
                </v-card-actions>
                <v-card-text>
                  <h2 class="mt-4">
                    Helping Penguins International <br />
                    Help Penguins
                  </h2>
                  <h3 class="mt-2">
                    A project designed to fund crucial scientific research
                    focused on conservation of penguin populations around the
                    world, as well as provide donors a direct connection to the
                    outcomes of their support.
                  </h3>
                  <h3>
                    <ol>
                      <li>Log in, or Create an account*</li>
                      <li>Sponsor an Incubator or Penguin Chick</li>
                      <li>Receive a Digital Collectible</li>
                      <li>
                        Get Access to Special Penguin Reports from Penguins
                        International
                      </li>
                    </ol>
                    <h5>
                      *You can use a social media, email account or Polygon
                      wallet to create your account and Login.
                    </h5>
                  </h3>
                </v-card-text>
                <v-card-actions v-if="loggedIn">
                  <v-btn color="blue" to="/user-collection" dark block x-large
                    ><v-icon class="mr-2">mdi-rhombus-split</v-icon>User
                    Collection</v-btn
                  >
                </v-card-actions>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="!loggedIn"
                    to="/login"
                    class="my-4"
                    color="purple"
                    dark
                    x-large
                    >Login / Create Account
                    <v-icon class="ml-2">mdi-login</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    href="https://www.penguinsinternational.org/penguin-incubator-project/"
                    class="mb-4"
                    target="_blank"
                    outlined
                    x-large
                    >Visit Penguins International
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>

                <v-card-text>
                  <h3 class="text-center">
                    Be sure to share your penguin with us on social media.
                  </h3>
                  <br />
                  <h4 class="text-center">
                    Instagram:
                    <a
                      href="https://www.instagram.com/penguins_international/"
                      target="_blank"
                      >@penguins_international</a
                    >
                  </h4>
                  <h4 class="text-center">
                    Facebook:
                    <a
                      href="https://www.facebook.com/PenguinsIntl/"
                      target="_blank"
                      >@PenguinsIntl</a
                    >
                  </h4>
                  <h4 class="text-center">
                    TikTok:
                    <a
                      href="https://www.tiktok.com/@penguinsinternational/"
                      target="_blank"
                      >@Penguinsinternational</a
                    >
                  </h4>
                  <h4 class="text-center">
                    Twitter:
                    <a
                      href="https://www.twitter.com/PenguinsIntl/"
                      target="_blank"
                      >@PenguinsIntl</a
                    >
                  </h4>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- Penguin Chicks Card -->
      <v-col cols="12" sm="4">
        <v-card class="nftw-box pa-1" rounded="0" aspect-ratio="1">
          <v-card-title>
            <h3>Penguin Chick Project</h3>
          </v-card-title>
          <v-card-actions>
            <v-btn color="pink" to="/pcp-checkout" block
              ><v-icon class="my-2 mr-2">mdi-penguin</v-icon>Hatch a Penguin
              Chick<v-icon class="my-2 ml-2">mdi-penguin</v-icon></v-btn
            >
            <br />
          </v-card-actions>
          <v-card-text>
            <h2 class="green--text text-right">Price: $18</h2>
            <br />
            <h3>
              We’d love for you to support the PIP project, but we understand
              that not everyone can commit to an entire incubator. Support the
              initiative anyway by purchasing a chick collectible for $18! Mint
              your penguin chick today! We’re excited to see which collectible
              hatches for you.
            </h3>
            <v-card-text class="text-center">
              <h3 class="text-h5">Number of Penguin Chicks Hatched:</h3>
              <a
                href="https://polygonscan.com/token/0x14146451079d7dd75e8b4b1a3df886f255d65e07#inventory"
                target="_blank"
              >
                <h1 class="mt-3 text-h3">{{ counts[0].minted }}</h1>
              </a>
            </v-card-text>
            <br />
            <h3 class="text-center">
              Your purchase will support penguin conservation at
              <br />

              <a href="https://www.penguinsinternational.org" target="_blank">
                Penguins International
              </a>
            </h3>
            <br />
            <penguin-chick-carousel></penguin-chick-carousel>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Incubator Card -->
      <v-col cols="12" sm="4">
        <v-card class="nftw-box pa-1" rounded="0" aspect-ratio="1">
          <v-card-title>
            <h3>Penguin Incubator Project</h3>
          </v-card-title>
          <v-card-actions>
            <v-btn color="yellow darken-2" to="pip-checkout" block
              ><v-icon class="my-2 mr-2">mdi-penguin</v-icon>Sponsor an
              Incubator<v-icon class="my-2 ml-2">mdi-penguin</v-icon></v-btn
            >
          </v-card-actions>
          <v-card-text>
            <h2 class="green--text text-right">Price: $1888</h2>
            <br />
            <h3>
              Minting one African Colony Digital Collectible will enable The
              Southern African Foundation for the Conservation of Coastal Birds
              - SANCCOB to purchase an incubator that will save as many as 180
              chicks per year and employ the staff to collect the abandoned
              eggs, care for the incubators, and ready the penguin chicks for
              release into the wild.
            </h3>
            <v-card-text class="text-center">
              <h3 class="text-h5">Number of Penguin Incubators Sponsored:</h3>
              <a
                href="https://polygonscan.com/token/0x6eedf7e748dff4f2a52d31ab1ca4f41e5d2355ff#inventory"
                target="_blank"
              >
                <h1 class="mt-3 text-h3">{{ counts[1].minted }}</h1>
              </a>
            </v-card-text>
            <br />
            <h3 class="text-center">
              Learn more at
              <br />

              <a
                href="https://www.penguinsinternational.org/penguin-incubator-project/"
                target="_blank"
              >
                Penguins International
              </a>
            </h3>
            <br />
            <penguin-incubator-carousel></penguin-incubator-carousel>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PenguinIncubatorCarousel from "@/components/PenguinIncubatorCarousel.vue";
import PenguinChickCarousel from "@/components/PenguinChickCarousel.vue";

export default {
  name: "HomeView",
  created() {
    this.bindColoniesRef();
    this.bindCountsRef();
  },
  mounted() {},

  components: { PenguinIncubatorCarousel, PenguinChickCarousel },
  data: () => ({}),
  methods: {
    ...mapActions([
      "bindUserOrders",
      "bindCountsRef",
      "bindPenguinsRef",
      "bindColoniesRef",
    ]),
  },
  computed: {
    ...mapState([
      "counts",
      "userOrders",
      "penguins",
      "colonies",
      "orders",
      "user",
      "loggedIn",
    ]),
  },
};
</script>
