import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Meta from 'vue-meta'


Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "register" */ '../views/RegisterUser.vue')
  },
  {
    path: '/user-info',
    name: 'user',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "user" */ '../views/UserView.vue')
  },
  {
    path: '/keeper-log',
    name: 'keeperView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "keeperView" */ '../views/admin/KeeperLog.vue')
  },
  {
    path: '/create',
    name: 'create',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "create" */ '../views/CreateNft.vue')
  },
  {
    path: '/checkout/:orgid/:cid/',
    name: 'checkout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "checkout" */ '../views/CheckoutPage.vue')
  },
  {
    path: '/pcp-checkout',
    name: 'pcpCheckout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pcpCheckout" */ '../views/PcpCheckout.vue')
  },
  {
    path: '/pip-checkout',
    name: 'pipCheckout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pcpCheckout" */ '../views/PipCheckout.vue')
  },
  {
    path: '/testcheckout',
    name: 'testCheckout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "testCheckout" */ '../views/TestCheckOut.vue')
  },
  {
    path: '/pip-order-success/cid/:cid/uid/:userAddress',
    name: 'pipOrderSuccess',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pipOrderSuccess" */ '../views/PipOrderSuccess.vue')
  },
  {
    path: '/pcp-order-success/cid/:cid/uid/:any',
    name: 'pcpOrderSuccess',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pcpOrderSuccess" */ '../views/PcpOrderSuccess.vue')
  },
  {
    path: '/order-cancel',
    name: 'orderCancel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "orderCancel" */ '../views/OrderCancelPage.vue')
  },
  {
    path: '/card-reader',
    name: 'cardReader',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cardReader" */ '../views/CardReader.vue')
  },
  {
    path: '/penguin-chick/:chickid',
    name: 'chickPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "chickPage" */ '../views/PenguinChickPage.vue')
  },
  {
    path: '/penguin-colony/:colonyid',
    name: 'colonyPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "colonyPage" */ '../views/PenguinColonyPage.vue')
  },
  {
    path: '/chicks',
    name: 'chicks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "chicks" */ '../views/PenguinChicks.vue')
  },
  {
    path: '/incubators',
    name: 'incubators',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "chicks" */ '../views/ColoniesView.vue')
  },
  {
    path: '/species/:colonyName',
    name: 'colonies',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "colonies" */ '../views/ColoniesView.vue')
  },
  {
    path: '/how-it-works',
    name: 'howItWorks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "howItWorks" */ '../views/HowItWorks.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "privacyPolicy" */ '../views/legal/PrivacyPolicy.vue')
  },
  {
    path: '/terms-of-service',
    name: 'termsOfService',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "termsOfService" */ '../views/legal/TermsOfService.vue')
  },
  {
    path: '/user-collection',
    name: 'userCollection',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "userCollection" */ '../views/UserCollection.vue')
  },
  {
    path: '/info-request',
    name: 'infoRequest',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "infoRequest" */ '../views/InfoRequestForm.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
});


export default router
