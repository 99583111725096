<template>
  <v-app>
    <v-app-bar app color="black" dark>
      <div class="d-flex align-center">
        <v-btn to="/" outlined>
          <v-icon class="mr-2">mdi-penguin</v-icon>
          <h2>TP.W</h2>
          <v-icon class="ml-2">mdi-penguin</v-icon>
        </v-btn>
      </div>

      <v-spacer></v-spacer>
      <h3 v-if="!loggedIn">Login <v-icon>mdi-arrow-right</v-icon></h3>
      <v-btn color="white" small fab>
        <v-avatar size="34" @click="userDrawer = !userDrawer">
          <v-img
            alt="TokenPenguin.World Logo"
            contain
            :placeholder="require('@/assets/TokenPenguinWorld.png')"
            :src="user.userInfo.profileImage"
            transition="scale-transition"
          />
        </v-avatar>
      </v-btn>
      <v-app-bar-nav-icon
        @click="userDrawer = !userDrawer"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <!-- User Drawer  -->

    <v-navigation-drawer v-model="userDrawer" app right>
      <v-card class="nftw-box pa-2 text-center" rounded="0" aspect-ratio="1">
        <v-img
          alt="TokenArt.World Logo"
          class="ma-4"
          contain
          :placeholder="require('@/assets/penguin-user.png')"
          :src="user.userInfo.profileImage"
          transition="scale-transition"
        />
        <h3 v-if="user.userInfo.name">{{ user.userInfo.name }}</h3>
        <h4 v-if="user.userInfo.email" class="text-center">
          {{ user.userInfo.email }}
        </h4>
        <h6 class="mx-6 text-center purple--text" v-if="user.address">
          {{ user.address }}
        </h6>
        <h6 v-if="user.address">&uarr; Your Public Address &uarr;</h6>
        <web-auth></web-auth>
        <v-card-actions v-if="!user.anonymous">
          <v-btn dark color="blue" block to="/user-collection">
            <v-icon class="mr-2">mdi-rhombus-split</v-icon> Collection
          </v-btn>
        </v-card-actions>

        <!-- <h5>
          Penguin Tokens: <br />1 <v-icon>mdi-penguin</v-icon
            ><v-icon>mdi-currency-eth</v-icon>
          </h5> -->
        <!-- <h4>From: {{user.typeOfLogin}}</h4> -->
        <v-card-actions>
          <v-btn color="green" dark to="/species/African" block
            ><v-icon class="mr-2">mdi-map</v-icon>View Colonies</v-btn
          >
        </v-card-actions>
        <v-card-actions>
          <v-btn outlined to="/privacy-policy" block>Privacy Policy</v-btn>
        </v-card-actions>

        <v-card-actions>
          <v-btn href="https://newfrontierworks.com" target="_blank" block dark
            >Visit NFTW</v-btn
          >
        </v-card-actions>
        <v-card class="ma-1 mt-3 pa-1" light>
          <a href="https://newfrontierworks.com" target="_blank">
            <v-img
              class="mx-auto"
              src="https://cloudflare-ipfs.com/ipfs/Qmd2vqZgoP2h7J3EnxSa7oUVfDk9RfLd6TSBo1nhmV9yLQ"
              max-width="188px"
            ></v-img>
          </a>
          <p class="text-center">
            This project was created by <br />
            NFTW
          </p>
        </v-card>
      </v-card>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
    <v-footer app height="44px">
      <!-- <v-btn @click="adminLogin" class="mr-2" fab>A</v-btn> -->
      <h6>&copy; NFTW 2023</h6>
      <!-- <a href="/terms-of-service"><h6> Terms of Service</h6></a>  -->
      <a class="ml-2" href="/privacy-policy"><h6>Privacy Policy</h6></a>
      <v-spacer></v-spacer>
      <div class="mx-auto">
        <v-fab-transition class="mb-2">
          <v-btn
            class="mb-4"
            to="/info-request"
            color="pink"
            dark
            absolute
            top
            right
            fab
          >
            <v-icon>mdi-chat-question</v-icon>
          </v-btn>
        </v-fab-transition>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import WebAuth from "@/components/auth/Web3Auth.vue";

export default {
  name: "App",
  components: { WebAuth },

  data: () => ({
    mainnet: false,
    userDrawer: false,
    appDrawer: false,
    clientIdTorus: process.env.VUE_APP_CLIENT_ID_TORUS_TESTNET,
    provider: null,
    web3auth: null,
    web3: null,
    accountAddress: null,
    balance: null,
    //
  }),
  mounted() {
    this.bindPenguinChicksRef();
  },
  methods: {
    ...mapActions(["bindPenguinChicksRef"]),
  },
  computed: mapState(["user", "loggedIn", "penguinChicks"]),
};
</script>

<style>
main {
  background-repeat: repeat;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='1000' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23FAFAFA' stroke-width='1.8'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23F6F6F6'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}
.headlink {
  text-decoration: none !important;
  color: white !important;
}
h1,
h2,
h3 {
  font-family: Righteous, Haettenschweiler, Franklin Gothic Bold, Charcoal,
    Helvetica Inserat, Bitstream Vera Sans Bold, Arial Black, sans serif;
  text-transform: none !important;
}
.v-card__text,
.v-card__title {
  word-break: normal !important;
}

.nftw-box {
  border-style: solid !important;
  border-color: black !important;
  border-width: 5px !important;
}
</style>