<template>
  <v-card elevation="24" max-width="512" class="mx-auto">
    <v-carousel
      :continuous="true"
      :cycle="cycle"
      :show-arrows="true"
      hide-delimiter-background
      delimiter-icon="mdi-minus"
    >
      <v-carousel-item
        v-for="(slide, i) in slides"
        :key="i"
        :src="slide.image"
        max-width="512px"
      >
      </v-carousel-item>
    </v-carousel>
    <v-list two-line>
      <v-list-item>
        <v-list-item-avatar>
          <v-img
            src="https://cloudflare-ipfs.com/ipfs/QmciiJRtEwHbRP2Bqh9J7XGu9RRJbBBhfYGH3YZyQGqYwA/penguin%2076.png"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>African Penguins</v-list-item-title>
          <v-list-item-subtitle>Penguin Chicks</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      cycle: true,
      slides: [
      {name: "Malgas Island", image: "https://firebasestorage.googleapis.com/v0/b/tokenpenguinworld-main.appspot.com/o/penguinEggs%2FPenguin-Egg%20(36).png?alt=media&token=4b3783b8-df7e-4249-9a14-924da771dcd5"},
        {name: "Dassen Island", image: "https://firebasestorage.googleapis.com/v0/b/tokenpenguinworld-main.appspot.com/o/penguinEggs%2FPenguin-Egg%20(33).png?alt=media&token=18d2dcdc-de32-405d-8f70-94e3a23f830b"},
        {name: "Robben Island", image: "https://firebasestorage.googleapis.com/v0/b/tokenpenguinworld-main.appspot.com/o/penguinEggs%2FPenguin-Egg%20(30).png?alt=media&token=678c30ef-d068-4c73-984d-f645c8ee1239"},
        {name: "Simon's Town", image: "https://firebasestorage.googleapis.com/v0/b/tokenpenguinworld-main.appspot.com/o/penguinEggs%2FPenguin-Egg%20(20).png?alt=media&token=86c9bda9-0dab-4901-85ca-9d85719a40fd"},
        {name: "Stony Point", image: "https://firebasestorage.googleapis.com/v0/b/tokenpenguinworld-main.appspot.com/o/penguinEggs%2FPenguin-Egg%20(38).png?alt=media&token=f051dc87-2af1-4695-a7c7-371b850df5f1"},
        {name: "Dyer Island", image: "https://firebasestorage.googleapis.com/v0/b/tokenpenguinworld-main.appspot.com/o/penguinEggs%2FPenguin-Egg%20(27).png?alt=media&token=91d054d3-6df7-46f4-a586-b187fb6c095c"},
        {name: "St Croix Island", image: "https://firebasestorage.googleapis.com/v0/b/tokenpenguinworld-main.appspot.com/o/penguinEggs%2FPenguin-Egg%20(19).png?alt=media&token=0dcb21a0-17e0-4d2e-90df-83aa60453b00"},
        {name: "Bird Island (Algoa Bay)", image: "https://firebasestorage.googleapis.com/v0/b/tokenpenguinworld-main.appspot.com/o/penguinEggs%2FPenguin-Egg%20(25).png?alt=media&token=2736057f-2fc8-413c-aeac-86a5c5eb407a"},
        {name: "De Hoop Marine Reserve", image: "https://firebasestorage.googleapis.com/v0/b/tokenpenguinworld-main.appspot.com/o/penguinEggs%2FPenguin-Egg%20(18).png?alt=media&token=fffeeae6-6fc2-497d-ad02-27be37cac2b0"}
      ],
    };
  },
};
</script>