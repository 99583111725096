<template>
    <v-card
      elevation="24"
      max-width="512"
      class="mx-auto"
    >
      <v-carousel
        :continuous="true"
        :cycle="cycle"
        :show-arrows="true"
        hide-delimiter-background
        delimiter-icon="mdi-minus"
      >
        <v-carousel-item
          v-for="(slide, i) in slides"
          :key="i"
          :src="slide.image"
           max-width="512px"
        >
              <v-row
          class="pa-4"
          justify="center"
        >
          <div class="text-h4 white--text">
            {{ slide.name }}
          </div>
        </v-row>
        </v-carousel-item>
      </v-carousel>
      <v-list two-line>
        <v-list-item>
          <v-list-item-avatar>
            <v-img src="https://cloudflare-ipfs.com/ipfs/QmciiJRtEwHbRP2Bqh9J7XGu9RRJbBBhfYGH3YZyQGqYwA/penguin%2076.png"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>African Penguins</v-list-item-title>
            <v-list-item-subtitle>Incubators</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </template>

<script>
export default {
  data () {
    return {
      cycle: true,
      slides: [
        {name: "Malgas Island", image: "https://firebasestorage.googleapis.com/v0/b/tokenpenguinworld-main.appspot.com/o/penguinIncubators%2FIncubator-Malgas.png?alt=media&token=18912957-58b6-43ae-8d95-68f75c1b396e"},
        {name: "Dassen Island", image: "https://firebasestorage.googleapis.com/v0/b/tokenpenguinworld-main.appspot.com/o/penguinIncubators%2FIncubator-Dassen.png?alt=media&token=abb87fe8-0879-4ed8-9ca4-2edab658ea76"},
        {name: "Robben Island", image: "https://firebasestorage.googleapis.com/v0/b/tokenpenguinworld-main.appspot.com/o/penguinIncubators%2FIncubator-Robbin.png?alt=media&token=a4f1d420-77fb-42ee-97bc-20dea33eaa1a"},
        {name: "Simon's Town", image: "https://firebasestorage.googleapis.com/v0/b/tokenpenguinworld-main.appspot.com/o/penguinIncubators%2FIncubator-Simons.png?alt=media&token=5554f14b-e672-447a-9681-54e1f033c8cc"},
        {name: "Stony Point", image: "https://firebasestorage.googleapis.com/v0/b/tokenpenguinworld-main.appspot.com/o/penguinIncubators%2FIncubator-Stony.png?alt=media&token=9a87f2c7-6be3-4f90-ab7d-35c80252c502"},
        {name: "Dyer Island", image: "https://firebasestorage.googleapis.com/v0/b/tokenpenguinworld-main.appspot.com/o/penguinIncubators%2FIncubator-Dyer.png?alt=media&token=6b1334e8-d0fa-4db4-a604-d0a87937ea35"},
        {name: "St Croix Island", image: "https://firebasestorage.googleapis.com/v0/b/tokenpenguinworld-main.appspot.com/o/penguinIncubators%2FIncubator-StCroix.png?alt=media&token=cc6eefe5-a82f-4ea2-8e07-aab5961b09e5"},
        {name: "Bird Island (Algoa Bay)", image: "https://firebasestorage.googleapis.com/v0/b/tokenpenguinworld-main.appspot.com/o/penguinIncubators%2FIncubator-BirdIsland.png?alt=media&token=e3331694-ea46-4dc8-be1a-73931bdc091e"},
        {name: "De Hoop Marine Reserve", image: "https://firebasestorage.googleapis.com/v0/b/tokenpenguinworld-main.appspot.com/o/penguinIncubators%2FIncubator-DeHoop.png?alt=media&token=09ad1730-5ef1-4951-9d5c-f78a9af89e9f"}
      ],
    }
  },
}
</script>