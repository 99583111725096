import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import createPersistedState from "vuex-persistedstate";
import firebase from 'firebase/compat/app'
import { db } from '@/firebase/db'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    checkoutOrganization: null,
    checkoutOrganizationPrices: [],
    loggedIn: false,
    firebaseAnonymousUser: null,
    user: {
      admin: false,
      userInfo: {
        name: "Anonymous",
        profileImage: require('@/assets/penguin-user.png')
      },
      anonymous: true, 
      address: null,
      balance: 0
  },
  chickMeta: [
    {id: "1", name: "Peggy", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%281%29.png"},
    {id: "2", name: "Polly", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2831%29.png"},
    {id: "3", name: "Porter", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%283%29.png"},
    {id: "4", name: "Pamela", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2850%29.png"},
    {id: "5", name: "Paul", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%285%29.png"},
    {id: "6", name: "Peter", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2852%29.png"},
    {id: "7", name: "Patty", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%287%29.png"},
    {id: "8", name: "Patrick", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2845%29.png"},
    {id: "9", name: "Penny", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2846%29.png"},
    {id: "10", name: "Perry", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2841%29.png"},
    {id: "11", name: "Percy", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2811%29.png"},
    {id: "12", name: "Penelope", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2854%29.png"},
    {id: "13", name: "Priscilla", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2832%29.png"},
    {id: "14", name: "Preston", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2856%29.png"},
    {id: "15", name: "Patricia", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2847%29.png"},
    {id: "16", name: "Philip", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2817%29.png"},
    {id: "17", name: "Pam", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2861%29.png"},
    {id: "18", name: "Paula", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2849%29.png"},
    {id: "19", name: "Perrin", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2828%29.png"},
    {id: "20", name: "Peyton", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2853%29.png"},
    {id: "21", name: "Pete", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2851%29.png"},
    {id: "22", name: "Puddles", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2833%29.png"},
    {id: "23", name: "Patches", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2820%29.png"},
    {id: "24", name: "Pip", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2820%29.png"},
    {id: "25", name: "Paige", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2834%29.png"},
    {id: "26", name: "Pablo", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2844%29.png"},
    {id: "27", name: "Prince", image: "https://cloudflare-ipfs.com//ipfs/QmbFMZDVq97NBAi79iyZV3M1oH8ppYwXK5k1CbUoZmjTjy/Penguin-Chick%20%2815%29.png"},
  ],
  orderRecords: [],
  userOrders: [],
  userOrdersCrypto: {order: "none"},
  transaction: {},
  order: null,
  orders: null,
  penguinOrder: null,
  colonyOrder: null,
  penguinChicks:[],
  penguins: [],
  colonies: [],
  penguinColony: [],
  counts: []
  },
  getters: {
  },
  mutations: {
    setCurrentUser(state, user) {
      state.user = user;
    },
    setCurrentOrder(state, order) {
      state.order = order;
    },
    setLoggedIn(state) {
      state.loggedIn = true;
    },
    setLoggedOut(state) {
      state.loggedIn = false;
    },
    setFirebaseAnonymousUser(state, payload) {
      state.firebaseAnonymousUser = payload;
    },
    setTransaction(state, payload) {
      state.transaction = payload;
    },
    ...vuexfireMutations
  },
  actions: {
    // Anonymous User Activity
    submitMessage: firestoreAction((context, message) => {
      // return the promise so we can await the write
      return db.collection('user-messages').doc(message.email).collection('messages').add({
        message,
        from: 'TokenPenguin.World-Message',
        createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      })
    }),
    createAnonymousFirestoreUser: firestoreAction((context, anonymousUser) => {
      // return the promise so we can await the write
      return db.collection('anonymous-users').doc(anonymousUser.uid).set({
        uid: anonymousUser.uid,
        from: 'TokenPenguin.World-Anonymous',
        createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      })
    }),
    logFirebaseAnonymousAuthUserReturn: firestoreAction((context, anonymousUser) => {
      // return the promise so we can await the write
      return db.collection('anonymous-users').doc(anonymousUser.uid).collection('logins').add({
        from: 'TokenPenguin.World-Anonymous',
        dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
    }),
    // Public User Activity
    createPublicFirestoreUser: firestoreAction((context, publicUser) => {
      // return the promise so we can await the write
      return db.collection('public-users').doc(publicUser.address).set({
        publicUser,
        originUID: publicUser.address,
        from: 'TokenPenguin.World-Public',
        lastVisit: firebase.firestore.FieldValue.serverTimestamp(),
      }, { merge: true })
    }),
    // Public User Activity
 
    logFirebasePublicAuthUserReturn: firestoreAction((context, publicUser) => {
      // return the promise so we can await the write
      return db.collection('public-users').doc(publicUser.address).collection('logins').add({
        originUID: publicUser.address,
        from: 'TokenPenguin.World-Public',
        dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
    }),
    // createPenguinChick: firestoreAction((context, chick) => {
      //   // return the promise so we can await the write
      //   return db.collection('penguin-chicks').add({
        //     chick,
        //     from: 'TokenPenguin.World-Public',
        //     dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
        //   })
        // }),
        createOrderUser: firestoreAction((context, order) => {
          // return the promise so we can await the write
          return db.collection('users').doc(order.uid).collection('pre-order-record').add({
            mintToken: {
              tokenMinted: false,
            },
            orderPaid: false,
            orderType: order.orderType,
            originUid: order.uid,
            originAccount: order.polygonAddress,
            order: order,
            from: 'TokenPenguin.World-Public',
            dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
          })
        }),
        createOrderRecord: firestoreAction((context, order) => {
          // return the promise so we can await the write
          return db.collection('pre-order-record').add({
            orderType: order.orderType,
            originUid: order.uid,
            originAccount: order.polygonAddress,
            order: order,
            from: 'TokenPenguin.World-Public',
            dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
          })
        }),
    cryptoOrderSuccess: firestoreAction((context, cryptoOrder) => {
      // return the promise so we can await the write
      return db.collection('users').doc(cryptoOrder.uid).collection('order-paid-crypto').doc(cryptoOrder.orderId).set({
        order: cryptoOrder,
        address: cryptoOrder.polygonAddress,
        tokenCid: cryptoOrder.tokenCid,
        from: 'Depay Crypto Order',
        dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
    }), 
    cryptoOrderSuccessRecord: firestoreAction((context, cryptoOrder) => {
      // return the promise so we can await the write
      return db.collection('order-paid-crypto').doc(cryptoOrder.orderId).set({
        uid: cryptoOrder.uid,
        order: cryptoOrder,
        address: cryptoOrder.polygonAddress,
        tokenCid: cryptoOrder.tokenCid,
        from: 'Depay Crypto Order',
        dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
    }),
    createOrderUserTest: firestoreAction((context, order) => {
      // return the promise so we can await the write
      return db.collection('users').doc(order.uid).collection('pre-order-record-test').add({
        mintToken: {
          tokenMinted: false,
        },
        orderPaid: false,
        orderType: order.orderType,
        originUid: order.uid,
        originAccount: order.polygonAddress,
        order: order,
        from: 'TokenPenguin.World-Public',
        dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
    }),
    createOrderRecordTest: firestoreAction((context, order) => {
      // return the promise so we can await the write
      return db.collection('pre-order-record-test').add({
        orderType: order.orderType,
        originUid: order.uid,
        originAccount: order.polygonAddress,
        order: order,
        from: 'TokenPenguin.World-Public',
        dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
    }),     
    cryptoOrderSuccessTest: firestoreAction((context, cryptoOrder) => {
      // return the promise so we can await the write
      return db.collection('users').doc(cryptoOrder.uid).collection('order-paid-crypto-test').doc(cryptoOrder.orderId).set({
        order: cryptoOrder,
        address: cryptoOrder.polygonAddress,
        tokenCid: cryptoOrder.tokenCid,
        from: 'Depay Crypto Order',
        dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
    }), 
    cryptoOrderSuccessRecordTest: firestoreAction((context, cryptoOrder) => {
      // return the promise so we can await the write
      return db.collection('order-paid-crypto-test').doc(cryptoOrder.orderId).set({
        uid: cryptoOrder.uid,
        order: cryptoOrder,
        address: cryptoOrder.polygonAddress,
        tokenCid: cryptoOrder.tokenCid,
        from: 'Depay Crypto Order',
        dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
    }),
    // mintToken: firestoreAction((context, token) => {
    //   // return the promise so we can await the write
    //   return db.collection('public-users').doc(token.address).collection('mintToken').doc(token.tokenCid).set({
    //     address: token.address,
    //     tokenCid: token.tokenCid,
    //     from: 'TokenPenguin.World-Public',
    //     dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
    //     tokenMinted: false
    //   })
    // }),
    // initializeMintToken: firestoreAction((context, token) => {
    //   // return the promise so we can await the write
    //   return db.collection('token-mints').doc(token.tokenCid).collection('mintToken').doc(token.address).set({
    //     address: token.address,
    //     tokenCid: token.tokenCid,
    //     from: 'TokenPenguin.World-Public',
    //     dateStamp: firebase.firestore.FieldValue.serverTimestamp(),
    //   })
    // }),
    createPenguin: firestoreAction((context, penguin) => {
      // return the promise so we can await the write
      return db.collection('penguins').doc(penguin.cid).set({
        penguin,
        species: penguin.penguinSpecies,
        createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      })
    }),
    createColony: firestoreAction((context, colony) => {
      // return the promise so we can await the write
      return db.collection('colonies').doc(colony.cid).set({
        colony,
        species: colony.colonySpecies,
        createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      })
    }),
    bindCountsRef: firestoreAction(context => {
      // context contains all original properties like commit, state, etc
      // and adds `bindFirestoreRef` and `unbindFirestoreRef`
      // we return the promise returned by `bindFirestoreRef` that will
      // resolve once data is ready
      return context.bindFirestoreRef('counts', db.collection('counts'))
    }),
    bindPenguinsRef: firestoreAction(context => {
      // context contains all original properties like commit, state, etc
      // and adds `bindFirestoreRef` and `unbindFirestoreRef`
      // we return the promise returned by `bindFirestoreRef` that will
      // resolve once data is ready
      return context.bindFirestoreRef('penguins', db.collection('penguins'))
    }),
    bindKeeperOrderRecordsRef: firestoreAction(context => {
      // context contains all original properties like commit, state, etc
      // and adds `bindFirestoreRef` and `unbindFirestoreRef`
      // we return the promise returned by `bindFirestoreRef` that will
      // resolve once data is ready
      return context.bindFirestoreRef('orderRecords', db.collection('order-records'))
    }),
    bindColoniesRef: firestoreAction(context => {
      // context contains all original properties like commit, state, etc
      // and adds `bindFirestoreRef` and `unbindFirestoreRef`
      // we return the promise returned by `bindFirestoreRef` that will
      // resolve once data is ready
      return context.bindFirestoreRef('colonies', db.collection('colonies'))
    }),
    bindPenguinChicksRef: firestoreAction(context => {
      // context contains all original properties like commit, state, etc
      // and adds `bindFirestoreRef` and `unbindFirestoreRef`
      // we return the promise returned by `bindFirestoreRef` that will
      // resolve once data is ready
      return context.bindFirestoreRef('penguinChicks', db.collection('penguin-chicks'))
    }),
    bindAiPenguinOrder: firestoreAction(({ bindFirestoreRef }, penguinCid) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef('penguinOrder', db.collection('penguins').where("penguin.cid", "==", penguinCid))
    }),
    bindAiColonyOrder: firestoreAction(({ bindFirestoreRef }, colonyCid) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef('colonyOrder', db.collection('colonies').where("colony.cid", "==", colonyCid))
    }),
    bindAiPenguinColony: firestoreAction(({ bindFirestoreRef }, colonyName) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef('penguinColony', db.collection('colonies').where("species", "==", colonyName))
    }),
    unbindAiPenguinColony: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('penguinColony')
    }),
    bindUserOrders: firestoreAction(({ bindFirestoreRef }, userAccount) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef('userOrders', db.collection('users').doc(userAccount.uid).collection('order-record'))
    }),
    unbindUserOrders: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('userOrders')
    }),
    bindUserOrdersCrypto: firestoreAction(({ bindFirestoreRef }, userAccount) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef('userOrdersCrypto', db.collection('public-users').doc(userAccount).collection('mintTokenCryptoOrderSuccess'))
    }),
    unbindUserOrdersCrypto: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('userOrdersCrypto')
    }),
    bindCheckoutOrganization: firestoreAction(({ bindFirestoreRef }, orgid) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef('checkoutOrganization', db.collection('organizations').doc(orgid))
    }),
    unbindCheckoutOrganization: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('checkoutOrganization')
    }),
    bindCheckoutOrganizationPrices: firestoreAction(({ bindFirestoreRef }, orgid) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef('checkoutOrganizationPrices', db.collection('organizations').doc(orgid).collection('prices'))
    }),
    unbindCheckoutOrganizationPrices: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('checkoutOrganizationPrices')
    }),
  
  },
  modules: {
  }
})
