<template>
  <v-card flat>
    <v-card-actions v-if="user.anonymous">
      <v-btn dark color="purple" block @click="login">
        <v-icon class="mr-2">mdi-login</v-icon> Login
        <v-icon class="ml-2">mdi-account</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-actions v-if="!user.anonymous">
      <v-btn dark color="purple" block @click="logout">
        <v-icon>mdi-account</v-icon> Logout
      </v-btn>
    </v-card-actions>
    <v-card-actions v-if="user.anonymous">
      <v-spacer></v-spacer>
      <v-icon>mdi-google</v-icon>
      <v-spacer></v-spacer>
      <v-icon>mdi-apple</v-icon>
      <v-spacer></v-spacer>
      <v-icon>mdi-facebook</v-icon>
      <v-spacer></v-spacer>
      <v-icon>mdi-twitter</v-icon>
      <v-spacer></v-spacer>
      <v-icon>mdi-email</v-icon>
      <v-spacer></v-spacer>
      <v-icon>mdi-ethereum</v-icon>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>


<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { Web3Auth } from "@web3auth/web3auth";
import { CHAIN_NAMESPACES } from "@web3auth/base";
import "firebase/compat/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import Web3 from "web3";

// Incase of secp256k1 curve, get the app_pub_key
import { getPublicCompressed } from "@toruslabs/eccrypto";

export default {
  name: "Web3Auth",

  components: {},
  data: () => ({
    provider: null,
    web3auth: null,
    web3: null,
    accountAddress: null,
    balance: null,
  }),
  async mounted() {
    console.log("current web3auth", this.web3auth);
    let clientId =
      "BOcU0afOX0rmSAtWRpGkGS8k_HZoLc3P7OhbXACpB0aa0PI5oDuz3N3-0xYl-jKeiy-G2h5onst_wHBvMcc7UFk";

    // console.log("in mounted.  Client id: ", clientId);

    if (!this.web3auth) {
      // console.log("web3auth not initialized yet");

      this.web3auth = new Web3Auth({
        clientId,
        uiConfig: {
          appLogo:
            "https://cloudflare-ipfs.com/ipfs/Qmd2vqZgoP2h7J3EnxSa7oUVfDk9RfLd6TSBo1nhmV9yLQ",
          theme: "dark",
          loginMethodsOrder: ["google", "apple", "facebook", "twitter"],
        },
        chainConfig: {
          chainNamespace: CHAIN_NAMESPACES.EIP155,
          chainId: "0x89",
          rpcTarget: "https://polygon-rpc.com", // This is the mainnet RPC we have added, please pass on your own endpoint while creating an app
        },
      });

      // console.log("Initializing web3auth");

      try {
        await this.web3auth.initModal();
      } catch (error) {
        console.log("error", error);
        console.log("error", error);
      }
      // const userinfo = this.web3auth.getUserInfo();
      // console.log("Got user info: ", userinfo);
      // this.getUserInfo();
    }
  },
  computed: mapState(["user", "loggedIn", "order"]),
  methods: {
    ...mapActions([
      "bindUserOrders",
      "unbindUserOrders",
      "bindColoniesRef",
      "bindPenguinChicksRef",
    ]),
    ...mapMutations(["setCurrentUser", "setLoggedIn", "setLoggedOut"]),
    async login() {
      if (!this.web3auth) {
        console.log("web3auth not initialized yet");
        return;
      }

      this.provider = await this.web3auth.connect();

      this.web3 = new Web3(this.provider);

      const userInfo = await this.web3auth.getUserInfo();

      // console.log("userInfo", userInfo);

      const token = await this.web3auth.authenticateUser();

      // console.log("token", token);

      if (!userInfo.profileImage) {
        userInfo.profileImage = require("@/assets/penguin-robot.png");
      }

      // console.log("user", this.user);

      // Get address from web3Auth related account

      const address = (await this.web3.eth.getAccounts())[0];

      this.accountAddress = address;
      // console.log("address", address);
      // Get user's balance in ether
      this.balance = this.web3.utils.fromWei(
        await this.web3.eth.getBalance(address) // Balance is in wei
      );

      this.verifyToken(token, userInfo, address);
    },

    async verifyToken(token, userInfo) {
      // console.log("In verifyToken");
      // console.log("Token: ", token);
      // console.log("web3auth.provider: ", this.web3auth.provider);
      // console.log("address: ", addressParam);

      // Determine if we have a social login or an external wallet
      let address;
      let app_pub_key;
      let authFunction;
      if (userInfo.name !== undefined && userInfo.name !== "") {
        // Social Login
        const app_scoped_privkey = await this.web3auth.provider.request({
          method: "eth_private_key", // use "private_key" for other non-evm chains
        });
        app_pub_key = getPublicCompressed(
          Buffer.from(app_scoped_privkey.padStart(64, "0"), "hex")
        ).toString("hex");
        address = this.accountAddress;
        authFunction = "penguinWeb3Auth";
        // console.log("Using social login for auth");
      } else {
        // External wallet
        address = (await this.web3.eth.getAccounts())[0];
        app_pub_key = address;
        authFunction = "penguinExternalWalletAuth";
        // console.log("Using external wallet for auth");
      }

      // console.log("app_pub_key ", app_pub_key);
      // console.log("address ", address);
      // console.log("authFunction ", authFunction);

      // Verify idToken with Firebase Function
      const functions = getFunctions();
      const addMessage = httpsCallable(functions, authFunction);
      addMessage({ idToken: token, app_pub_key: app_pub_key, address: address })
        .then((result) => {
          // Read result of the Cloud Function.

          const customToken = result.data.customToken;
          // Sign in using the custom token
          const auth = getAuth();
          // console.log("Read customToken from function call: ", customToken);
          // console.log("Auth: ", auth);

          signInWithCustomToken(auth, customToken)
            .then((userCredential) => {
              // Signed in
              const userCred = userCredential.user;
              console.log("Signed in as user ", userCred);
              let uid = userCred.uid;

              let user = {
                userCred,
                uid,
                anonymous: false,
                userInfo,
                address,
                balance: this.balance,
              };
              this.bindColoniesRef();
              this.bindPenguinChicksRef();
              this.bindUserOrders(user);
              this.setCurrentUser(user);
              this.setLoggedIn();
            })
            .catch((error) => {
              const errorCode = <error className="code"></error>;
              const errorMessage = error.message;
              console.log(
                "Error signing in with token ",
                errorCode,
                errorMessage
              );
            });
        })
        .catch((error) => {
          // Getting the Error details.
          const code = error.code;
          const message = error.message;
          console.log("Error getting auth token: ", code, message);
        });
    },

    async logout() {
      if (!this.web3auth) {
        console.log("web3auth not initialized yet");
        return;
      }
      // console.log("provider", this.provider);

      let user = {
        userInfo: {
          profileImage: require("@/assets/penguin-user.png"),
        },
        anonymous: true,
        address: null,
        balance: 0,
      };

      this.unbindUserOrders();
      this.setCurrentUser(user);
      this.setLoggedOut();
      this.loggedOut;

      await this.web3auth.logout();
      this.balance = null;
      this.$router.push("/");
    },
  },
};
</script>